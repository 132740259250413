/* This example requires Tailwind CSS v2.0+ */
import { Bars4Icon } from '@heroicons/react/24/outline'
import { AccountIcon } from 'app/components/Base'
import { tabs } from 'app/config/app'
import { ROUTES } from 'app/config/routes'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectTab, setTab } from 'features/switchTab/switchTabSlice'
import {
  setIsTelephoneRang,
  telephoneRang,
} from 'features/telephoneRang/telephoneRangSlice'
import useAudio from 'hooks/useAudio'
import useChangeTab from 'hooks/useChangeTab'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { fullDateTimeWeekFormat } from 'utils/datetime'

export default function Header(props: {
  setSidebarOpen: (arg0: boolean) => void
  isCollapsed: boolean
}) {
  const { setSidebarOpen, isCollapsed } = props
  const dispatch = useAppDispatch()
  const [now, setNow] = useState(new Date())
  const location = useLocation()
  const { t } = useTranslation()
  const tab = useAppSelector(selectTab)
  const isPhoneRang = useSelector(telephoneRang)
  const { patientId } = useParams<string>()
  const handleMultipleChangeTab = useChangeTab()

  const navigate = useNavigate()

  useAudio({
    url: '/audio/ringtone01.mp3',
    playing: isPhoneRang,
    setPlaying: (status: boolean) => dispatch(setIsTelephoneRang(status)),
    options: {
      loop: true,
    },
  })

  let isHomePage = false
  let isPatientProfilePage = false
  let isShowLabel = false
  let isStaff = false
  let isPatient = false
  let isSetting = false

  const classTab = {
    default:
      'w-[15.6vw] min-h-full h-full focus:outline-none text-[#333] text-[18px] rounded mr-[1px] font-semibold',
    active: 'bg-[#FFFFFF] pointer-events-none',
    inactive: 'bg-gray-80',
  }

  if (location.pathname === ROUTES.HOME) {
    isHomePage = true
  }

  if (location.pathname.indexOf('/patient/') === 0) {
    isPatientProfilePage = true
  }

  if (
    location.pathname === ROUTES.STAFF.INDEX ||
    location.pathname.indexOf('/staffs/') === 0
  ) {
    isShowLabel = true
    isStaff = true
  }

  if (location.pathname === ROUTES.PATIENTS.INDEX) {
    isShowLabel = true
    isPatient = true
  }

  if (location.pathname === ROUTES.MASTER_SETTING.INDEX) {
    isSetting = true
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date())
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const classLabel = 'rounded-none bg-white'

  const handleClickDetailPatient = (idPatient: string, tabValue: string) => {
    dispatch(setTab(tabs.CONSULTATION))
    const route = ROUTES.PATIENTS.DETAIL.replace(':patientId', idPatient)
    const state = { tab: tabValue }
    return navigate(route, { state })
  }

  return (
    <div
      className={`sticky top-0 z-10 flex flex-shrink-0 h-12 bg-white  bg-gray ${
        isCollapsed ? 'pl-0' : 'pl-0'
      }`}
    >
      <button
        type="button"
        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars4Icon className="w-6 h-6" aria-hidden="true" />
      </button>
      <div className="w-full flex justify-between">
        <div className="flex h-full items-center bg-gray-80">
          {isHomePage && (
            <>
              <button
                type="button"
                onClick={() => {
                  dispatch(setTab(tabs.TOP))
                  navigate(`${ROUTES.HOME}?tab=${tabs.TOP}`, {
                    state: `${tabs.TOP}`,
                  })
                }}
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.TOP ? classTab.active : classTab.inactive
                }`}
              >
                {t('pages.Home.MedicalExam.title')}
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setTab(tabs.TODAY_TASK))
                  navigate(
                    `${ROUTES.HOME}?tab=${tabs.TODAY_TASK}&sub_tab=${tabs.WAITING_ACCOUNTING}`,
                    {
                      state: `${tabs.TODAY_TASK}`,
                    },
                  )
                }}
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.TODAY_TASK ? classTab.active : classTab.inactive
                }`}
              >
                {t('pages.Home.TodayTask.title')}
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setTab(tabs.SHIPPING_ADDRESS))
                  navigate(`${ROUTES.HOME}?tab=${tabs.SHIPPING_ADDRESS}`, {
                    state: `${tabs.SHIPPING_ADDRESS}`,
                  })
                }}
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.SHIPPING_ADDRESS
                    ? classTab.active
                    : classTab.inactive
                }`}
              >
                {t('pages.Home.ShippingAddress.title')}
              </button>
            </>
          )}
          {isShowLabel && (
            <button
              type="button"
              onClick={() => {
                dispatch(setTab(tabs.TOP))
              }}
              className={`${classTab.default} ${classLabel}`}
            >
              {isStaff
                ? t('pages.Staff.title')
                : isPatient
                ? t('pages.Patient.title')
                : ''}
            </button>
          )}
          {isPatientProfilePage && (
            <>
              <button
                type="button"
                onClick={
                  patientId
                    ? () => handleClickDetailPatient(patientId, tabs.PROFILE)
                    : () => {}
                }
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.PROFILE ? classTab.active : classTab.inactive
                }`}
              >
                {t('labels.PatientProfile.patient_information')}
              </button>
              <button
                type="button"
                onClick={
                  patientId
                    ? () =>
                        handleClickDetailPatient(patientId, tabs.CONSULTATION)
                    : () => {}
                }
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.CONSULTATION
                    ? classTab.active
                    : classTab.inactive
                }`}
              >
                {t('labels.PatientProfile.consultation')}
              </button>
              <button
                type="button"
                onClick={
                  patientId
                    ? () => handleClickDetailPatient(patientId, tabs.ACCOUNTING)
                    : () => {}
                }
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.ACCOUNTING ? classTab.active : classTab.inactive
                }`}
              >
                {t('labels.PatientProfile.accounting')}
              </button>
            </>
          )}

          {isSetting && (
            <>
              <button
                type="button"
                onClick={() =>
                  handleMultipleChangeTab(
                    `${`${ROUTES.MASTER_SETTING.INDEX}?tab=${tabs.WORK_SCHEDULE}`}`,
                  )
                }
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.WORK_SCHEDULE
                    ? classTab.active
                    : classTab.inactive
                }`}
              >
                {t('pages.WorkSchedule.title')}
              </button>
              <button
                type="button"
                onClick={() =>
                  handleMultipleChangeTab(
                    `${`${ROUTES.MASTER_SETTING.INDEX}?tab=${tabs.HOSPITAL_SETTING}&sub_tab=${tabs.BASIC_SETTINGS}`}`,
                  )
                }
                className={`${classTab.default} ${classLabel} ${
                  tab === tabs.HOSPITAL_SETTING
                    ? classTab.active
                    : classTab.inactive
                }`}
              >
                {t('pages.HospitalSetting.title')}
              </button>
            </>
          )}
        </div>
        <div className="flex items-center mr-[14px]">
          <div className="mr-10">
            <AccountIcon />
          </div>
          <span className="text-white whitespace-nowrap text-base font-light">
            {fullDateTimeWeekFormat(now)}
          </span>
        </div>
      </div>
    </div>
  )
}
