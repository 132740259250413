import { apiClient } from './api'

export const questionnaireResponse = {
  create: async (data: any) => {
    const response = await apiClient.post(
      `/api/v1/questionnaire-responses`,
      data,
    )
    return response
  },
  findAll: async (params: any = {}) => {
    const response = await apiClient.get(`/api/v1/questionnaire-responses`, {
      params,
    })
    return response
  },
  getLatest: async (params: { patientId: string; departmentId: string }) => {
    const response = await apiClient.get(
      `/api/v1/questionnaire-responses/latest`,
      { params },
    )
    return response
  },

  findOne: async (id: string) => {
    const response = await apiClient.get(`api/v1/questionnaire-responses/${id}`)
    return response
  },
}

export default questionnaireResponse
