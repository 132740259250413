import { ROUTES } from 'app/config/routes'
import { Home } from 'app/containers/Home/Loadable'
import { Login } from 'app/containers/Login/Loadable'
import { NotFoundPage } from 'app/containers/NotFoundPage/Loadable'
// import { Patient } from 'app/containers/Patient/Loadable'
import { PatientList as Patient } from 'app/containers/Patient/PatientList/Loadable'
import { PatientProfile } from 'app/containers/PatientProfile/Loadable'
import { DummyAttendee } from 'app/containers/DummyAttendee'
import { QuestionerResponse } from 'app/containers/QuestionerResponse'
import { StaffList as Staff } from 'app/containers/Staff/StaffList/Loadable'
import { BlankLayout } from 'app/layouts/BlankLayout'
import { UpdateOrCreateStaff } from 'app/containers/Staff/UpdateOrCreate'
import { Analysis } from 'app/containers/Analysis/Loadable'
import { ScheduleAndSettings } from 'app/containers/ScheduleAndSettings/Loadable'
import { QuestionerForm } from 'app/containers/QuestionerTemplate/QuestionerForm/Loadable'

const routes = [
  {
    path: ROUTES.HOME,
    element: Home,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.DUMMY_ATTENDEE,
    element: DummyAttendee,
    meta: {
      middleware: [],
    },
  },
  {
    path: ROUTES.QUESTIONNAIRE_RESPONSE.INDEX,
    element: QuestionerResponse,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: 'questioners-form/:id',
    element: QuestionerForm,
    layout: BlankLayout,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.STAFF.INDEX,
    element: Staff,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.STAFF.CREATE,
    element: UpdateOrCreateStaff,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.STAFF.UPDATE,
    element: UpdateOrCreateStaff,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.MASTER_SETTING.INDEX,
    element: ScheduleAndSettings,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.AUTH.LOGIN,
    element: Login,
    layout: BlankLayout,
    meta: {
      middleware: [],
    },
  },
  {
    path: ROUTES.PATIENTS.INDEX,
    element: Patient,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.PATIENTS.DETAIL,
    element: PatientProfile,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: ROUTES.ANALYSIS.INDEX,
    element: Analysis,
    meta: {
      middleware: [],
    },
  },
  {
    path: '*',
    element: NotFoundPage,
    layout: BlankLayout,
    meta: {
      middleware: [],
    },
  },
]

export { routes }
