import { Staff as StaffType, StaffPost as StaffPostType } from 'types/Staff'
import { apiClient } from './api'

export const getStaffs = async (params: any = {}) => {
  const data: any = await apiClient.get<StaffType[]>('/api/v1/staffs/all', {
    params,
  })
  return data
}

export const createStaffV2 = async (data: any) => {
  const dataStaff: StaffType = await apiClient.post('/api/v1/staffs', data)
  return dataStaff
}

export const getStaffsV2 = async (params: any = {}) => {
  const data: any = await apiClient.get<StaffType[]>('/api/v1/staffs', {
    params,
  })
  return data
}

export const getInfoStaffV2 = async (staffId: string) => {
  const data: StaffType = await apiClient.get(`/api/v1/staffs/${staffId}`, {})
  return data
}

export const deleteStaffV2 = async (staffsId: String) => {
  const data: StaffType = await apiClient.delete(
    `/api/v1/staffs/${staffsId}`,
    {},
  )
  return data
}
export const updateStaffV2 = async (staffId: string, data: any) => {
  const dataStaff: StaffPostType = await apiClient.put(
    `/api/v1/staffs/${staffId}`,
    data,
  )
  return dataStaff
}

export default {
  getStaffs,
  createStaffV2,
  getInfoStaffV2,
  getStaffsV2,
  deleteStaffV2,
  updateStaffV2,
}
