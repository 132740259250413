import { ROUTES } from 'app/config/routes'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setHeader } from 'app/services/api'
import { authHTApi } from 'app/services/auth'
import { getUser } from 'app/services/user'
import {
  selectAccessToken,
  selectCurrentUser,
  selectIsAuthenticated,
  setAccessToken,
  setCurrentUser,
  setIsAuthenticated,
} from 'features/auth/authSlice'
import {
  selectLastRunTime,
  setLastRunTime,
} from 'features/lastRunTime/lastRunTimeSlice'
import { useEffect } from 'react'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'

export function PrivateRoute(props: any) {
  const navigate = useNavigate()
  const { children } = props
  const idToken = useAppSelector(selectAccessToken)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const lastRunTime = useAppSelector(selectLastRunTime)
  const currentUser = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  let isMounted = true

  async function init() {
    if (!idToken) {
      return navigate(ROUTES.AUTH.LOGIN)
    }
    try {
      const user = await getUser()
      dispatch(setCurrentUser(user))
      dispatch(setIsAuthenticated(true))
    } catch (error: any) {
      dispatch(setCurrentUser(null))
      dispatch(setAccessToken(null))
      dispatch(setIsAuthenticated(false))
      return navigate(ROUTES.AUTH.LOGIN)
    }
  }

  const refreshTokenId = (myDecodedToken: any) => {
    let intervalId: any

    try {
      const currentTime = new Date().getTime()
      const refreshToken = idToken.refreshToken as string
      const thirtyMinutesInMillis = 30 * 60 * 1000 // 30 minutes = 30 * 60 * 1000 milliseconds
      let timeRemaining = thirtyMinutesInMillis
      if (lastRunTime) {
        const lastRunTimeInMillis = parseInt(lastRunTime, 10)
        const elapsedTime = currentTime - lastRunTimeInMillis
        timeRemaining =
          thirtyMinutesInMillis - (elapsedTime % thirtyMinutesInMillis)
      }

      intervalId = setInterval(() => {
        if (!isMounted) {
          clearInterval(intervalId)
          return
        }

        const payload = {
          userPoolName: 'clinic-admin',
          userName: myDecodedToken.sub,
          refreshToken,
        }

        authHTApi.getUserAccessToken(payload).then(async (newUser: any) => {
          const { idToken: newIdToken } = newUser.data
          const authToken = {
            token_type: 'Bearer',
            idToken: newIdToken,
            refreshToken,
          }
          dispatch(setAccessToken(authToken))
          setHeader(authToken)
        })
      }, timeRemaining)
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('error refresh token', error)
    }

    return intervalId
  }

  useEffect(() => {
    init()
    const intervalIdLastRunTime = setInterval(() => {
      if (isMounted) {
        dispatch(setLastRunTime(new Date().getTime().toString()))
      }
    }, 1000)

    return () => {
      clearInterval(intervalIdLastRunTime)
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let intervalId: any = null

    if (currentUser && idToken) {
      const myDecodedToken: any = decodeToken(idToken?.idToken)

      if (myDecodedToken) {
        const expirationTimeInSeconds = myDecodedToken.exp

        const currentTimestampInSeconds = Math.floor(lastRunTime / 1000)
        const oneHourInSeconds = 3600 // 1 hours = 3600 seconds

        const timeUntilExpiration =
          expirationTimeInSeconds - currentTimestampInSeconds

        if (timeUntilExpiration <= oneHourInSeconds) {
          intervalId = refreshTokenId(myDecodedToken)
        }
      }
    }

    return () => clearInterval(intervalId)
  }, [currentUser])

  return !isAuthenticated ? (
    <section className="flex h-screen justify-center items-center">
      Loading
    </section>
  ) : (
    children
  )
}
