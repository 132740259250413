import { DefaultFeeBillingType } from 'types/Billing'

export const PERMISSIONS: any = {
  doctor: '医師',
  staff: '事務員',
  tester: 'テスター',
}
export const ADMIN = 'admin'
export const DIRECTOR = 'director'
export const AUDIENCE = 'audience'
export const ARTIST = 'artist'

export const MEDICAL_EXAMINATION_DEPARTMENTS: any = {
  INTERNAL_MEDICINE: '内科',
  ALLERGY: 'アレルギー科',
  DERMATOLOGY: '皮膚科',
  PEDIATRICS: '小児科',
}

export const MEDICAL_EXAMINATION_SECTIONS = [
  'INTERNAL_MEDICINE',
  'ALLERGY',
  'DERMATOLOGY',
  'PEDIATRICS',
]

export const MEDICAL_EXAMINATION_SECTION = {
  INTERNAL_MEDICINE: 'INTERNAL_MEDICINE',
  DERMATOLOGY: 'DERMATOLOGY',
  ALLERGY: 'ALLERGY',
  PEDIATRICS: 'PEDIATRICS',
}

export const MEDICAL_EXAMINATION_STATUS: any = {
  FACE_TO_FACE: 'FACE_TO_FACE',
  WAITING_CONSULTATION: 'WAITING_CONSULTATION',
  ENTERING_MEDICAL_DOCUMENTS: 'ENTERING_MEDICAL_DOCUMENTS',
  NOT_CONSULTATION_STARTED: 'NOT_CONSULTATION_STARTED',
  UNDER_CONSULTATION: 'UNDER_CONSULTATION',
  SELECTING_PAYMENT_METHOD: 'SELECTING_PAYMENT_METHOD',
  AWAITING_ACCOUNTING_NOTIFICATION: 'AWAITING_ACCOUNTING_NOTIFICATION',
  DURING_ACCOUNTING_PAYPAY: 'DURING_ACCOUNTING_PAYPAY',
  ERROR_SETTLEMENT: 'ERROR_SETTLEMENT',
  ENTERING_PHARMACY_INFORMATION: 'ENTERING_PHARMACY_INFORMATION',
  AWAITING_DELIVERY_TO_PHARMACY: 'AWAITING_DELIVERY_TO_PHARMACY',
  AWAITING_DELIVERY_TO_HOME: 'AWAITING_DELIVERY_TO_HOME',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
}

export const STORAGE_DOCUMENT_PATHS_TYPES = {
  identificationCard: 1,
  insuranceCard: 2,
  medicalCertificate: 3,
  otherDocuments: 4,
  otherDocumentQuestionnaire: 5,
}

export const WAITING_MEDICAL_EXAMINATION_STATUS_LIST = [
  'FACE_TO_FACE',
  'WAITING_CONSULTATION',
  'ENTERING_MEDICAL_DOCUMENTS',
  'NOT_CONSULTATION_STARTED',
]
export const STATUS_ENABLE_CALL = [
  'WAITING_CONSULTATION',
  'NOT_CONSULTATION_STARTED',
  'UNDER_CONSULTATION',
  'SELECTING_PAYMENT_METHOD',
  'AWAITING_ACCOUNTING_NOTIFICATION',
]
export const UNDER_MEDICAL_EXAMINATION_STATUS_LIST = ['UNDER_CONSULTATION']
export const SELECT_PAYMENT_METHOD_MEDICAL_EXAMINATION_STATUS_LIST = [
  'SELECTING_PAYMENT_METHOD',
  'AWAITING_ACCOUNTING_NOTIFICATION',
  'DURING_ACCOUNTING_PAYPAY',
  'ERROR_SETTLEMENT',
]
export const SHIPPING_MEDICAL_EXAMINATION_STATUS_LIST = [
  'ENTERING_PHARMACY_INFORMATION',
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
]
export const STATUS_OF_TASKS = [
  'SELECTING_PAYMENT_METHOD',
  'AWAITING_ACCOUNTING_NOTIFICATION',
  'DURING_ACCOUNTING_PAYPAY',
  'ERROR_SETTLEMENT',
  'ENTERING_PHARMACY_INFORMATION',
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
]
export const COMPLETE_MEDICAL_EXAMINATION_STATUS_LIST = ['COMPLETED']
export const CANCEL_MEDICAL_EXAMINATION_STATUS_LIST = ['CANCELED']
export const GROUP_STATUS_OF_TASKS = {
  WAITING_ACCOUNTING: 'waitingAccounting',
  WAITIING_SHIPPING: 'waitingShipping',
}

export const MEDICAL_EXAMINATION_STATUS_DEFINITION: any = {
  FACE_TO_FACE: '対面診療',
  WAITING_CONSULTATION: '診察待ち',
  ENTERING_MEDICAL_DOCUMENTS: '保険証等未提出',
  NOT_CONSULTATION_STARTED: '応答なし',
  UNDER_CONSULTATION: '診察中',
  AWAITING_ACCOUNTING_NOTIFICATION: '会計待ち',
  SELECTING_PAYMENT_METHOD: '支払い方法未選択',
  DURING_ACCOUNTING_PAYPAY: 'PayPay未払い',
  ERROR_SETTLEMENT: '決済エラー',
  ENTERING_PHARMACY_INFORMATION: '薬局未選択',
  AWAITING_DELIVERY_TO_PHARMACY: '発送待ち',
  AWAITING_DELIVERY_TO_HOME: '発送待ち',
  COMPLETED: '完了',
  CANCELED: '診察取消',
}
export const STATUS_CAN_CANCEL_SETTLEMENT = [
  'DURING_ACCOUNTING_PAYPAY',
  'ERROR_SETTLEMENT',
  'ENTERING_PHARMACY_INFORMATION',
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
]

export const STATUS_REQUIRED_VALIDATE_WHEN_SHIPPING = [
  'DURING_ACCOUNTING_PAYPAY',
  'ENTERING_PHARMACY_INFORMATION',
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
]

export const STATUS_REQUIRED_PACKAGE_NUMBER = [
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
]

export const STATUS_CAN_CANCEL = [
  'FACE_TO_FACE',
  'WAITING_CONSULTATION',
  'ENTERING_MEDICAL_DOCUMENTS',
  'NOT_CONSULTATION_STARTED',
  'SELECTING_PAYMENT_METHOD',
  'AWAITING_ACCOUNTING_NOTIFICATION',
  'DURING_ACCOUNTING_PAYPAY',
  'ERROR_SETTLEMENT',
]

export const GENDER: any = {
  MALE: '男性',
  FEMALE: '女性',
  ORTHER: 'その他',
}

export const REASON_LABEL_MAPPING: any = {
  DISORDER: '診療してほしい',
  NEED_MEDICINE: '薬が欲しい',
  CONSULTATION: '健診結果などを医師に相談したい',
  REPEATED: '当院での前回の治療の続き',
}

export const REASON_LABEL_MAPPING_V1_10: any = {
  DISORDER: '診療してほしい',
  NEED_MEDICINE: '薬が欲しい',
  CONSULTATION: '健診や検査の結果を相談したい',
  REPEATED: '当院での前回の治療の続き',
}

export const FIRST_SORT_ORDER_REASON_LABEL_MAPPING = [
  'DISORDER',
  'NEED_MEDICINE',
  'CONSULTATION',
  'REPEATED',
]

export const ONSET_OF_SYMPTOMS: any = {
  TODAY: '今日',
  '3DAYS_AGO': '〜3日前',
  '1WEEK_AGO': '〜１週間前',
  '1MONTH_AGO': '〜１ヶ月前',
  HALF_YEAR_AGO: '〜半年前',
  MORE_THAN_HALF_YEAR_AGO: '半年以上前',
  UNKNOWN: '不明・その他',
}
export const IMAGE_SIZE_EXCEEDS = 'いずれかの画像サイズが8MBを超えています'

// export const ALLERGIES: any = {
//   MEDICINE: '薬 ',
//   FOOD: '食品 ',
//   OTHER: 'その他 ',
// }
// export const FIRST_SORT_ORDER_ALLERGIES = ['MEDICINE', 'FOOD', 'OTHER']
// export const MEDICAL_AND_SURGICAL_HISTORIES: any = {
//   HEART_DISEASE: '心疾患（心筋梗塞、狭心症など）',
//   STROKE: '脳卒中（脳梗塞、脳出血など）',
//   CANCER: 'がん',
//   STOMACH_SURGERY: 'おなかの手術（盲腸、胆石など)',
// }

// export const FIRST_SORT_ORDER_MEDICAL_AND_SURGICAL_HISTORIES = [
//   'HEART_DISEASE',
//   'STROKE',
//   'CANCER',
//   'STOMACH_SURGERY',
// ]

// export const DISEASES_UNDER_TREATMENT: any = {
//   HYPERTENSION: '高血圧 ',
//   DIABETES_MELLITUS: '糖尿病 ',
//   HEART_DISEASE: '心臓病 ',
//   RENAL_DISEASE: '腎臓病 ',
//   HEPATIC_DISEASE: '肝臓病 ',
//   ASTHMA: 'ぜんそく ',
//   PULMONARY_DISEASE: '肺の病気（ぜんそく以外） ',
//   GLAUCOMA: '緑内障 ',
//   GYNECOLOGY_DISEASE: '婦人科の病気 ',
//   OTHER: 'その他 ',
// }

// export const FIRST_SORT_ORDER_DISEASES_UNDER_TREATMENT = [
//   'HYPERTENSION',
//   'DIABETES_MELLITUS',
//   'HEART_DISEASE',
//   'RENAL_DISEASE',
//   'HEPATIC_DISEASE',
//   'ASTHMA',
//   'PULMONARY_DISEASE',
//   'GLAUCOMA',
//   'GYNECOLOGY_DISEASE',
//   'OTHER',
// ]

export const MONTHS_OF_PREGNANCY: any = {
  UNKNOWN: '不明',
  LESS_THAN_3MONTH: '〜3ヶ月',
  '4MONTH': '4ヶ月',
  '5MONTH': '5ヶ月',
  '6MONTH': '6ヶ月',
  '7MONTH': '7ヶ月',
  '8MONTH': '8ヶ月',
  '9MONTH': '9ヶ月',
  '10MONTH': '10ヶ月',
}

export const KIND_OF_PRESCRIPTION: any = {
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
  NONE: 'NONE',
}

export const KIND_OF_DELIVERY: any = {
  CB: 'CB',
  YOU_PACKET: 'YOU_PACKET',
  YOU_PACK: 'YOU_PACK',
  MAIL_TO_HOME: 'MAIL_TO_HOME',
  MAIL_TO_PHARMACY: 'MAIL_TO_PHARMACY',
  YAMATO: 'YAMATO',
  LETTER_PACK_HOME: 'LETTER_PACK_HOME',
  LETTER_PACK_PHARMACY: 'LETTER_PACK_PHARMACY',
  NONE: 'NONE',
}

export const KIND_OF_DELIVERY_OUTSIDE: any = {
  MAIL_TO_HOME: 'MAIL_TO_HOME',
  MAIL_TO_PHARMACY: 'MAIL_TO_PHARMACY',
  LETTER_PACK_HOME: 'LETTER_PACK_HOME',
  LETTER_PACK_PHARMACY: 'LETTER_PACK_PHARMACY',
}
export const KIND_OF_DELIVERY_INSIDE: any = {
  CB: 'CB',
  YOU_PACKET: 'YOU_PACKET',
  YOU_PACK: 'YOU_PACK',
  YAMATO: 'YAMATO',
}

export const KIND_OF_DELIVERY_HAVE_PACKAGE_NUMBER: any = {
  CB: 'CB',
  YOU_PACKET: 'YOU_PACKET',
  YOU_PACK: 'YOU_PACK',
  YAMATO: 'YAMATO',
  LETTER_PACK_PHARMACY: 'LETTER_PACK_PHARMACY',
  LETTER_PACK_HOME: 'LETTER_PACK_HOME',
}

export const TAB: any = {
  TOP_TASK: 'TopTask',
  TODAY_MEDICAL_TREATMENT: 'TodayMedicalTreatment',
}
export const TABS: any = {
  TOP: 'top',
  TODAY_TASK: 'today_task',
  PROFILE: 'profile',
  CONSULTATION: 'consultation',
  ACCOUNTING: 'accounting',
}

export const UNABLE_UPDATE_DELIVERY_STATUS = [
  MEDICAL_EXAMINATION_STATUS.FACE_TO_FACE,
  MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS,
  MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED,
  MEDICAL_EXAMINATION_STATUS.COMPLETED,
  MEDICAL_EXAMINATION_STATUS.CANCELED,
]

export const ENABLE_EDIT_BILLING_STATUS = [
  MEDICAL_EXAMINATION_STATUS.AWAITING_ACCOUNTING_NOTIFICATION,
  MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
]

export const STATUS_CAN_UPDATE_PDF_FILE = [
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.AWAITING_ACCOUNTING_NOTIFICATION,
  MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
  MEDICAL_EXAMINATION_STATUS.DURING_ACCOUNTING_PAYPAY,
  MEDICAL_EXAMINATION_STATUS.ERROR_SETTLEMENT,
]

export const STATUS_EDIT_FEE_AMOUNT = [
  MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED,
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
  MEDICAL_EXAMINATION_STATUS.ERROR_SETTLEMENT,
  MEDICAL_EXAMINATION_STATUS.AWAITING_ACCOUNTING_NOTIFICATION,
]

export const DISABLE_BUTTON_ACCOUNTING = [
  MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS,
  MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED,
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
]

export const STATUS_TASK_SHIPPING_ADDRESS = [
  MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS,
  MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
  MEDICAL_EXAMINATION_STATUS.ENTERING_PHARMACY_INFORMATION,
  MEDICAL_EXAMINATION_STATUS.AWAITING_ACCOUNTING_NOTIFICATION,
  MEDICAL_EXAMINATION_STATUS.AWAITING_DELIVERY_TO_HOME,
  MEDICAL_EXAMINATION_STATUS.COMPLETED,
  MEDICAL_EXAMINATION_STATUS.CANCELED,
]

export const MAX_OF_OTHER_FEE = 13
export const CLINIC_ID: string = '1a62dd23-c64f-4883-b5fb-5f21581b5df0'
export const FIFTEEN_MINUTES = 15
export const PAYMENT_METHOD_OTHER = 'OTHER'

export const DELIVERY_METHOD_KIND_ADDRESS = {
  YAMATO: 'YAMATO',
  CB: 'CB',
  YOU_PACKET: 'YOU_PACKET',
  YOU_PACK: 'YOU_PACK',
  NONE: 'NONE',
  LETTER_PACK_HOME: 'LETTER_PACK_HOME',
  MAIL_TO_HOME: 'MAIL_TO_HOME',
}

export const KIND_USE_PHARMACY_INFORMATION = [
  'LETTER_PACK_PHARMACY',
  'MAIL_TO_PHARMACY',
]

export const SYMPTOMS_MAPPING = [
  '発熱',
  'のどの痛み',
  '咳',
  '痰',
  'くしゃみ',
  '鼻詰まり',
  '目の痒み',
  'のどの痒み',
  'その他',
]

export const DISEASE_NAME_MAPPING_V1_9 = [
  'なし',
  '高血圧症',
  '糖尿病',
  '花粉症',
  '生活習慣病',
  '脳疾患',
  '心臓病',
  '肝臓病',
  '腎臓病',
  'アレルギー疾患',
  '甲状腺疾患',
  '婦人科の病気',
  '目の病気',
  '精神疾患',
  'その他',
]

export const DISEASE_NAME_MAPPING_V1_10 = [
  'なし',
  '高血圧症',
  '糖尿病',
  '花粉症',
  '生活習慣病',
  '心疾患',
  '肝機能障害',
  '腎臓病',
  '脳疾患',
  'アレルギー疾患',
  '甲状腺疾患',
  '婦人科の病気',
  '目の病気',
  '精神疾患',
  'その他',
]

export const QUESTIONNAIRE_VERSION_ONE_POINT_NINE = '1.9'
export const QUESTIONNAIRE_VERSION_ONE_POINT_TEN = '1.10'

export const ONE_DAY = 1

export const MEDICAL_EXAMINATION_STATUS_CONSULTATION: any = [
  'WAITING_CONSULTATION',
  'ENTERING_MEDICAL_DOCUMENTS',
  'NOT_CONSULTATION_STARTED',
  'UNDER_CONSULTATION',
  'CANCELED',
]

export const MEDICAL_EXAMINATION_STATUS_ACCOUNTING: any = [
  'SELECTING_PAYMENT_METHOD',
  'AWAITING_ACCOUNTING_NOTIFICATION',
  'DURING_ACCOUNTING_PAYPAY',
  'ERROR_SETTLEMENT',
  'ENTERING_PHARMACY_INFORMATION',
  'AWAITING_DELIVERY_TO_PHARMACY',
  'AWAITING_DELIVERY_TO_HOME',
  'COMPLETED',
]

export const tabs = {
  TOP: 'top',
  TODAY_TASK: 'today_task',
  PROFILE: 'profile',
  CONSULTATION: 'consultation',
  ACCOUNTING: 'accounting',
  SHIPPING_ADDRESS: 'top_shipping_address',
  HOSPITAL_SETTING: 'hospital_setting',
  WORK_SCHEDULE: 'work_schedule',
  BASIC_SETTINGS: 'basic_settings',
  MENU_SETTINGS: 'menu_settings',
  QUESTIONNAIRE_SETTING: 'questionnaire_setting',
  WAITING_ACCOUNTING: 'waiting_accounting',
  WAITING_SHIPPING: 'waiting_shipping',
}

export const STORAGE_TARGET_PATHS_TYPES = {
  temporaryFolder: 1,
  temporaryFilePath: 2,
  customerDocumentFilePath: 3,
  customerQuestionnairesFilePath: 4,
  staffFilePath: 5,
  specificationFilePath: 6,
  pharmacyImportPath: 7,
}

export const DEPARTMENT_CATEGORY = {
  SECTION: 'SECTION',
  MEDICINE: 'MEDICINE',
}

export const CURRENT_PAGE = 'CURRENT_PAGE'

export const NO_ANSWER = '回答なし'

export const PERMISSION_OPTIONS = [
  { value: 'doctor', label: '医師' },
  { value: 'staff', label: '事務員' },
  { value: 'tester', label: 'テスター' },
]

export const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/heic']

export const MAX_OF_OTHER_DOCUMENT = 5

export const DAYS = ['月', '火', '水', '木', '金', '土', '日']

export const MAX_SIZE_UPLOAD_IMAGE = 8

export const MAX_SIZE_UPLOAD = 4.5

export const MEGABYTE = 1024 * 1024

export const NOTI_WEBSOCKET = {
  NEW_RESERVATION: '新しい予約があります。',
  UPDATE_RESERVATION: '更新があります。',
}

export const DEFAULT_FEE_BILLING: Record<string, DefaultFeeBillingType> = {
  [KIND_OF_PRESCRIPTION.INSIDE]: {
    amount: null,
    deliveryCharge: 550,
    usageFee: 0,
    prescriptionDeliveryCharge: 0,
  },
  [KIND_OF_PRESCRIPTION.OUTSIDE]: {
    amount: null,
    deliveryCharge: 0,
    usageFee: 0,
    prescriptionDeliveryCharge: 100,
  },
  [KIND_OF_PRESCRIPTION.NONE]: {
    amount: null,
    deliveryCharge: 0,
    usageFee: 0,
    prescriptionDeliveryCharge: 0,
  },
}
